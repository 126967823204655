<template>
<div class="page">
  <question-item :qa="info" :limit="false"></question-item>
</div>
</template>

<script>
import QuestionItem from "./QuestionItem";
import { getQa } from "@/api/qa";
import to from "@/utils/to";
export default {
  components: {
    QuestionItem,
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getData();
  },
  data() {
    return {
      id: null,
      info: {},
    };
  },
  methods: {
    async getData() {
      this.$showLoading("加载中...");
      let [err, res] = await to(getQa(this.id));
      this.$hideLoading();
      if (err != null) {
        console.log("加载错误", err);
        return;
      }

      this.info = res;
    },
  },
};
</script>

<style socped>
.page {
  padding: 16px;
}
</style>